import React ,{Fragment,useEffect,useContext,useState}from 'react';
import './Projectportifolio.scss';
import {Applicationcontext} from "../applicationcontext";
import {Grid,Container, Typography} from '@mui/material';






const  Projectportifolio = (() => { //--------------MAIN FUNCTION----------------
  
 const ApplicationState = useContext(Applicationcontext)


  const {ResponsiveMediaQuery,UserProfile,Functions,AppplicationSetting} = ApplicationState

  const {PersonalProfile,BusinessProfile} = UserProfile

  const {profile_photo,shopping_cart_items} = PersonalProfile

  const {top_navigation_link} = AppplicationSetting

  const {theme_maincolor,theme_subcolor,theme_light_color,theme_decoration_color,company_logo} = BusinessProfile


  //const {BusinessProfile} = UserProfile

 // const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

  //const {business_name} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery





  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------


return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------


}
//eslint-disable-next-line react-hooks/exhaustive-deps
},[])



// const LogoutCleaningCache = ()=>{
//   return new Promise(function(resolve,reject){

    
    
//     return resolve(Cookies.remove('account_csrftoken'))
// })
// }





// const LogoutFunction = ()=>{


//   LogoutCleaningCache().then(function(Response){


//    localStorage.setItem('logout_message','You_have_been_loged_out_successfully')
//     var LogoutPageReload = $('#logout-page-reload')
//     LogoutPageReload[0].click()

//   })

 
  
// }




const [ProjectGalleryList,setProjectGalleryList] = useState([

  {title:'spot check and cross-check of water tank trenching of foundation in commissioned M&E project.',photo:'projects/water-tank-trenching.png'},
  {title:'spot check and cross-check of tank basement construction during  Routine Monitoring.',photo:'projects/water-take-basement-construction.png'},
 
  {title:'cross-check of project material mobilization in one of the commissioned M&E projects.',photo:'projects/material mobilization.png'},
  {title:'cross-check of material mobilization in Routine M&E.',photo:'projects/cement-mobilization.png'},
  {title:'Tinkas M&E Consults officer conducting community participatory M&E  with project beneficiary one of the commissioned M&E project during routine monitoring.',photo:'projects/consults-officer-with-kumwambu.png'},
  {title:'Tinkas M&E Consults during cross-check and spot check of water tank measurement for quality assurance during routine M&E in one of the commissioned M&E.',photo:'projects/water-tanks-measurements.png'},
  {title:'cross-check of construction of water tanks.',photo:'projects/water-tank-progress-2.png'},
  {title:'cross-check of drilled well and covered for protection.',photo:'projects/drilled-well-covered.png'},
  {title:'cross-check and spot-check mobilized equipment of the contracted services during routine M&E.',photo:'projects/drilling-mashine.png'},
 
])







const ProjectsListMap=ProjectGalleryList.map(function(item,index){
return(
<Fragment key={`PropertySubcategory${index}`}>
    <Grid item xs={12} className='project-grid-container'>
      <Grid container spacing={1}> 
        <Grid item xs={12}>
            <div className='project-photo'>
            <img src={item.photo} alt='' />
         </div>  
     </Grid>
         
      <Grid item xs={12}>
        <div className='project-description'>
          <Typography variant='h6'>{item.title}</Typography>
       </div>
      </Grid>
        </Grid> 
     </Grid>
 </Fragment>
 )})







return (   //--------RETURN HTML---------------------------
<Fragment>
<div id='Projectportifolio'>
<Grid container spacing={1}>


<Grid item xs={12}>
<div class="section-title position-relative pb-3 mb-5">
  <h5 class="fw-bold text-primary text-uppercase">Projects Progress Tracking</h5>
  <h1 class="mb-0">
  TINKAS MONITORING AND EVALUATION CONSULTS, We keep track of ongoing 
  projects to ensure the fast and yet proper implementation of projects 
  as well as quality and integrity</h1>
</div>
</Grid>





{ProjectsListMap}



</Grid>
</div>
</Fragment>
 
);


// Projectportifolio.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// Projectportifolio.defaultProps ={}


})
export  {Projectportifolio}

