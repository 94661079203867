import React ,{Fragment,useEffect}from 'react';
import './Aboutpage.scss';
import {Grid,Button} from '@mui/material';









const  Aboutpage = (({DisplayFull=true}) => { //--------------MAIN FUNCTION----------------
  
// const ApplicationState = useContext(Applicationcontext)

  //const {ResponsiveMediaQuery,UserProfile,Functions,App,AppplicationSetting} = ApplicationState

  //const {PersonalProfile,BusinessProfile} = UserProfile

  //const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

 // const {theme_light_color,theme_maincolor,theme_subcolor,business_name,company_logo,theme_decoration_color} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


 // const {mobile_xs,tablet_sm,desktop_md,desktop_xl} = ResponsiveMediaQuery







  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------





  

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------





  

}

// eslint-disable-next-line react-hooks/exhaustive-deps

},[])










// const MailNotificationTrigger=()=>{
   
//   //$('#mail-notification-dropdown').trigger('click')

//   document.getElementById('mail-notification-dropdown').click();

   

 
// }




//0767867862
//0782467852 kazembe 





// ln -s /etc/nginx/sites-available/tinkasmec.conf /etc/nginx/sites-enabled/

// sudo ln -s /etc/nginx/sites-available/tinkasmec.conf /etc/nginx/sites-enabled/tinkasmec.conf

return (   //--------RETURN HTML---------------------------
<Fragment>
<div id='Aboutpage'>
<Grid container spacing={1}>

<Grid item xs={12}>
<Grid container spacing={2}> 

<Grid item xs={12} md={7}>
<div className="section-title position-relative pb-3 mb-5">
  <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
  <h1 className="mb-0">TINKAS IS THE BEST IN MONITORING & EVALUATION CONSULTS </h1>
</div>
  <p className="mb-4">
  <img src='images/ABOUT-1.PNG' />
  TINKAS Monitoring and Evaluation Consultants is a national management consulting
      firm registered in BRELLA no 466150 as per business registration Act (Cap 213) Tanzania,
       and the Tax Identification Number is 116-141-108. The firm is specialized in Management
       Consultancies for Non- Government organizations, Faith Based Organizations, and Government
        Institutions around Tanzania. The company holds leading positions with its two business
        units in both Monitoring and Evaluation.   

        Combining the experience of professionals and experiences from a variety of backgrounds,
        the firm delivers high-quality consulting and implementation of Monitoring and evaluation
        support to address the needs of clients in public and private sector Tanzania.
        Tinkas Monitoring and evaluation consults is based in Kagera Region, Bukoba Council District,
        Bilele ward and Jamhuri Road near Government Hospital, with easy access to different clients
        that includes faith-based organizations, Non-Government organizations (Non-Profit and Profit making Organizations from local and international)
        institutions of different calibers.

  </p>    
  {DisplayFull===false?<Button variant='outlined'>ReadMore</Button>:null} 
</Grid>

<Grid item xs={12} md={5} className='text-justify-settings'>
<img src='images/tinkasmec-full-logo.png' alt='' />
<p className="mb-4">
        The firm is a multidisciplinary M&E consultancy service majorly in health care, education,
        theology/religions, legal and human rights, Nutrition and food technology, and economics. Our commitment
        is to provide “brand name” advisory services of the highest quality, with the agility and flexibility
        that contemporary realities demand.
        Our ambition is to deliver real benefits to all stakeholders through the work we do. We offer,
        M&E Services Consultancies, Strategic Management Services, Commissioned Program, and Project
        Management services, Business Planning and Management, Financial and Profitability Analysis
        of the projects, programs, and Organization and Research and Development Consultancies.       
</p>

</Grid>


</Grid>
</Grid>


{DisplayFull===true?
<Grid item xs={12}>
<Grid container spacing={1}> 

<Grid item xs={12} sm={7}>
<h1 className="mb-0">Our Mission </h1>
<p className="mb-4">
  Keeping in innovations to reach the a leading M&E consulting firm
</p>
<img src='images/mission-photo.png' alt='' className='mission-photo'/>
</Grid>


<Grid item xs={12} sm={7}>
<div className="section-title position-relative pb-3 mb-5">
<h1 className="mb-0">OUR VISION </h1>
 </div>
  <p className="mb-4">

    A leading M&E consulting firm in Tanzania and beyond
  </p>

<div className="section-title position-relative pb-3 mb-5">
     <h1 className="mb-0">OUR CORE VALUE </h1>
     <p className="mb-4">
     We believe in results
     </p>
 </div>

</Grid>


<Grid item xs={12} sm={7}>
<h1 className="mb-0">Our Expertise</h1>
<p className="mb-4">
 Our technical expertise is complimented by strengths that include:
</p>



<div className="row g-0 mb-3">
<div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'zoomIn'}}>
  <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />A strong focus on delivering results</h5>
  <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />•	Proven ability to work with different organization. </h5>
</div>
<div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'zoomIn'}}>
  <h5 className="mb-3"><i className="fa fa-check text-primary me-3" />•	A keen understanding of the marketplace acquired through employment and the execution of consultancy assignments.</h5>
</div>
</div>

</Grid>





</Grid>
</Grid>
:null}




</Grid>
</div>  
</Fragment>
 
);


// Aboutpage.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// Aboutpage.defaultProps ={}


})
export  {Aboutpage}

