import React ,{Fragment,useEffect,useState}from 'react';
import './ProjectEvaluationPresentation.scss';
import {Grid,Button, Typography} from '@mui/material';









const  ProjectEvaluationPresentation = (({DisplayFull=true}) => { //--------------MAIN FUNCTION----------------
  
// const ApplicationState = useContext(Applicationcontext)

  //const {ResponsiveMediaQuery,UserProfile,Functions,App,AppplicationSetting} = ApplicationState

  //const {PersonalProfile,BusinessProfile} = UserProfile

  //const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

 // const {theme_light_color,theme_maincolor,theme_subcolor,business_name,company_logo,theme_decoration_color} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


 // const {mobile_xs,tablet_sm,desktop_md,desktop_xl} = ResponsiveMediaQuery







  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------





  

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------





  

}

// eslint-disable-next-line react-hooks/exhaustive-deps

},[])



const [ProjectGalleryList,setProjectGalleryList] = useState([

  {title:'Focused group discussion in project evaluation.',photo:'images/evaluation-section-1.png'},
  {title:'Project Evaluation Interview.',photo:'images/evaluation-section-2.png'},
  {title:'Female Dressing Rooms.',photo:'images/evaluation-section-3.png'},
  {title:'',photo:'images/evaluation-section-4.png'},
  {title:'',photo:'images/evaluation-section-5.png'},
  {title:'',photo:'images/evaluation-section-6.png'},

 ])
 
 
 
 
 
 
 
 const ProjectsListMap=ProjectGalleryList.map(function(item,index){
 return(
 <Fragment key={`PropertySubcategory${index}`}>
     <Grid item xs={12} className='project-grid-container'>
       <Grid container spacing={1}> 
         <Grid item xs={12}>
             <div className='project-photo'>
             <img src={item.photo} alt='' />
          </div>  
      </Grid>
          
       <Grid item xs={12}>
         <div className='project-description'>
           <Typography variant='h6'>{item.title}</Typography>
        </div>
       </Grid>
         </Grid> 
      </Grid>
  </Fragment>
  )})
 
 
 


return (   //--------RETURN HTML---------------------------
<Fragment>
<div id='ProjectEvaluationPresentation'>
<Grid container spacing={1}>

<Grid item xs={12}>
<div class="section-title position-relative pb-3 mb-5">
  <h5 class="fw-bold text-primary text-uppercase">TinkasMec Running Project Evaluation Program</h5>
  <h1 class="mb-0">PROJECT EVALUATION ACTIVITIES</h1>
</div>
</Grid>





{ProjectsListMap}



</Grid>
</div>  
</Fragment>
 
);


// ProjectEvaluationPresentation.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// ProjectEvaluationPresentation.defaultProps ={}


})
export  {ProjectEvaluationPresentation}

