import React ,{Fragment,useEffect,useContext}from 'react';
import './ExperienceSection.scss';
import {Applicationcontext} from "../applicationcontext";
import {Grid,Container, Typography} from '@mui/material';






const  ExperienceSection = (() => { //--------------MAIN FUNCTION----------------
  
 const ApplicationState = useContext(Applicationcontext)


  const {ResponsiveMediaQuery,UserProfile,Functions,AppplicationSetting} = ApplicationState

  const {PersonalProfile,BusinessProfile} = UserProfile

  const {profile_photo,shopping_cart_items} = PersonalProfile

  const {top_navigation_link} = AppplicationSetting

  const {theme_maincolor,theme_subcolor,theme_light_color,theme_decoration_color,company_logo} = BusinessProfile


  //const {BusinessProfile} = UserProfile

 // const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

  //const {business_name} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery





  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------




  

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------





  

}

//eslint-disable-next-line react-hooks/exhaustive-deps
},[])



// const LogoutCleaningCache = ()=>{
//   return new Promise(function(resolve,reject){

    
    
//     return resolve(Cookies.remove('account_csrftoken'))
// })
// }





// const LogoutFunction = ()=>{


//   LogoutCleaningCache().then(function(Response){


//    localStorage.setItem('logout_message','You_have_been_loged_out_successfully')
//     var LogoutPageReload = $('#logout-page-reload')
//     LogoutPageReload[0].click()

//   })

 
  
// }









return (   //--------RETURN HTML---------------------------
<Fragment>
<div id='ExperienceSection'>
<Grid container spacing={2}>

<Grid item xs={12} sm={6}>
<div className="section-title position-relative pb-3 mb-5">
  <h5 className="fw-bold text-primary text-uppercase">Specialization & Experience</h5>
  <h1 className="mb-0">OUR EXTENSIVE EXPERIENCE </h1>
 </div>
  <p className="mb-4">
  TINKAS has more than 18 years of experience conducting many sorts of M&E assignments of different 
  cadres in various organizations including NGOs, FBOs, public entities, and private sectors. 
  Over the past years, we have successfully conducted evaluations in the agriculture, education,
   governance, health, microfinance, manufacturing industry, public administration, and public finance.
 </p>
  <img src='images/experience.png' className='experience-photo'/>
</Grid>

<Grid item xs={12} sm={6}>
<Grid container spacing={3}> 

<Grid item xs={12} md={5}>
<div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
      <div className="service-icon">
        <i className="fa fa-shield-alt text-white" />
      </div>
      <h4 className="mb-3">Works Flexibility</h4>
      <p className="m-0">Proven ability to work with different organization.</p>
      <a className="btn btn-lg btn-primary rounded" href>
        <i className="bi bi-arrow-right" />
      </a>
    </div>
</Grid>


<Grid item xs={12} md={5}>
<div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
      <div className="service-icon">
        <i className="fa fa-shield-alt text-white" />
      </div>
      <h4 className="mb-3">Market Place Proffession</h4>
      <p className="m-0">
       A keen understanding of the marketplace acquired through employment
        and the execution of consultancy assignments.
       </p>
      <a className="btn btn-lg btn-primary rounded" href>
        <i className="bi bi-arrow-right" />
      </a>
</div>
</Grid>



<Grid item xs={12} md={5}>
<div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
      <div className="service-icon">
        <i className="fa fa-shield-alt text-white" />
      </div>
      <h4 className="mb-3">Strong Focus</h4>
      <p className="m-0">A strong focus on delivering results and renovating an existing ideals.</p>
      <a className="btn btn-lg btn-primary rounded" href>
        <i className="bi bi-arrow-right" />
      </a>
    </div>
</Grid>





<Grid item xs={12} md={5}>
<div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
      <div className="service-icon">
        <i className="fa fa-shield-alt text-white" />
      </div>
      <h4 className="mb-3">Integrity</h4>
      <p className="m-0">
      TINKAS believes that integrity and the disciplined management of risk
         form the foundation of our business.
         
          </p>
      <a className="btn btn-lg btn-primary rounded" href>
        <i className="bi bi-arrow-right" />
      </a>
    </div>
</Grid>





</Grid>
</Grid>



</Grid>
</div>
</Fragment>
 
);


// ExperienceSection.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// ExperienceSection.defaultProps ={}


})
export  {ExperienceSection}

