import React ,{Fragment,useEffect,useContext}from 'react';
import {Route,Routes} from 'react-router-dom';
import {Applicationcontext} from "./applicationcontext";
import {Homepage} from "./Homepage/Homepage";
import {Aboutpage} from "./Aboutpage/Aboutpage";
import {Servicepage} from "./Servicepage/Servicepage";
import {Contactpage} from "./Contactpage/Contactpage";
import {Projectportifolio} from "./Projectportifolio/Projectportifolio";











const  LandingpageNavigation = (props) => {

    
    const ApplicationState = useContext(Applicationcontext)

    const {UserProfile} = ApplicationState

    const {BusinessProfile,PersonalProfile} = UserProfile
    
    const {execute,read,write,role} = PersonalProfile






useEffect(()=>{//COMPONENT DID MOUNT




    


 
    


},[])




  







return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>

 <Routes>
  
  <Route path="/" element={<Homepage />} />

  <Route path="/services.html" element={<Servicepage />} />

  <Route path="/about.html" element={<Aboutpage />} />

  <Route path="/contact.html" element={<Contactpage />} />

  <Route path="/project.html" element={<Projectportifolio />} />

   
{/* 
      <Route path="/aboutpage/company" element={<Aboutpage />} />
      





      <Route path="/shopping/category/:MAIN_CATEGORY_KEY" element={<CategoryDisplayView />} />

      <Route path="/shopping/category/:MAIN_CATEGORY_KEY/:SUB_CATEGORY_KEY" element={<CategoryDisplayView />} />

      <Route path="/shopping/category/:MAIN_CATEGORY_KEY/:SUB_CATEGORY_KEY/:INNER_CATEGORY_KEY" element={<CategoryDisplayView />} />

     
      <Route path="/shopping/category/:MAIN_CATEGORY_KEY/:SUB_CATEGORY_KEY/:INNER_CATEGORY_KEY/:INNER_MOST_CATEGORY_KEY" element={<CategoryDisplayView />} />
      
     
      


      <Route path="/authentication/login" element={<UserLogin />} />
      <Route path="/authentication/registration" element={<UserRegistration />} /> */}

      <Route path="*" element={<h1>PAGE NOT FOUND ! </h1>} />
</Routes>


</Fragment>
);}
export {LandingpageNavigation};

