import React ,{Fragment,useEffect}from 'react';
import {Route,Routes} from 'react-router-dom';
import Landingpage from "./Landingpage/Landingpage";


const  RouteNavigation = (props) => {




// const Location = useLocation()

// const {pathname} = Location


useEffect(()=>{//COMPONENT DID MOUNT



   






 
    


},[])




  








return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>


    <Routes>
    
      <Route path="login" element={<h1>LOGIN PAGE</h1>} />

      <Route path="/*"  element={<Landingpage />} />
      
      <Route path="*" element={<h1>PAGE NOT FOUND </h1>} />


    </Routes>



</Fragment>
);
}
export {RouteNavigation};

