import React ,{Fragment,useEffect,useContext}from 'react';
import './Servicepage.scss';
import {Applicationcontext} from "../applicationcontext";
import {Grid,Container, Typography} from '@mui/material';
import {ProjectEvaluationPresentation} from "../ProjectEvaluationPresentation/ProjectEvaluationPresentation";






const  Servicepage = (({DisplayFull=true}) => { //--------------MAIN FUNCTION----------------
  
 const ApplicationState = useContext(Applicationcontext)


  const {ResponsiveMediaQuery,UserProfile,Functions,AppplicationSetting} = ApplicationState

  const {PersonalProfile,BusinessProfile} = UserProfile

  const {profile_photo,shopping_cart_items} = PersonalProfile

  const {top_navigation_link} = AppplicationSetting

  const {theme_maincolor,theme_subcolor,theme_light_color,theme_decoration_color,company_logo} = BusinessProfile


  //const {BusinessProfile} = UserProfile

 // const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

  //const {business_name} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery





  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------




  

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------





  

}

//eslint-disable-next-line react-hooks/exhaustive-deps
},[])



// const LogoutCleaningCache = ()=>{
//   return new Promise(function(resolve,reject){

    
    
//     return resolve(Cookies.remove('account_csrftoken'))
// })
// }





// const LogoutFunction = ()=>{


//   LogoutCleaningCache().then(function(Response){


//    localStorage.setItem('logout_message','You_have_been_loged_out_successfully')
//     var LogoutPageReload = $('#logout-page-reload')
//     LogoutPageReload[0].click()

//   })

 
  
// }









return (   //--------RETURN HTML---------------------------
<div id='Servicepage'>
<Grid container spacing={2}>


<Grid item xs={12}>
<div className="wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
<div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
      <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
      <h1 className="mb-0">
         TINKAS have an experienced and skilled staff
         of executing any of the following assignments.
        </h1>
</div>
</div>
</Grid>

<Grid item xs={12} sm={4}>
 <div className="wow zoomIn" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'zoomIn'}}>
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'auto'}}>
          <h4 className="mb-3">M&E training consultancies</h4>
          <p className="m-0">
             M&E systems development, M&E systems assessment, routine, and non-routine monitoring systems,
             M&E data management processes, M&E supportive supervision and data auditing, M&E information
             utilization, M&E research, and evaluation. Database development. M&E, theories, frameworks
             , and tools development and application, M&E digital services, and spatial application.
             M&E partnership and advocacy, M&E capacity building.
          </p>
          <a className="btn btn-lg btn-primary rounded" href=''>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div>  
</Grid>

<Grid item xs={12} sm={4}>
 <div className="wow zoomIn" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'zoomIn'}}>
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'auto'}}>
          <h4 className="mb-3">M&E Field Consultancies: </h4>
          <p className="m-0">
          Conduct evaluations (clarificatory, processes, and outcomes) of the programs, projects,
           and institutions for direct and indirect decisions. Conducting performance monitoring
           of client’s programs, projects, and policies and reporting direct to funders, managers, or officers
           or as per agreement. Conducting M&E compliances including data auditing, and supportive supervision.
            Conducting residential and commissioned research, M&E systems. Conducting Project Appraisal
             assessment.
          </p>
          <a className="btn btn-lg btn-primary rounded" href=''>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div> 
</Grid>


<Grid item xs={12} sm={4}>
 <div className="wow zoomIn" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'zoomIn'}}>
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'auto'}}>
          <h4 className="mb-3">M&E products consultancies</h4>
          <p className="m-0">
          Development of M&E plan document, Project Monitoring and Evaluation Plan (PMP),
           M&E Project/Program protocol indicators, M&E data collection processes tools,
           Logical Models, frameworks, organization theories of changes, organization data
            flow chart, M&E processes and outcomes reporting tools, and data quality assurance
            tools. Digital data collection tools. M&E assignment Terms of Reference (TOR)
             development. Project Appraisal tools development.
          </p>
          <a className="btn btn-lg btn-primary rounded" href=''>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div> 
</Grid>


<Grid item xs={12} sm={4}>
 <div className="wow zoomIn" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'zoomIn'}}>
        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center" style={{height:'auto'}}>
          <h4 className="mb-3">Other consultancy services</h4>
          <p className="m-0">
          Development of strategic plan documents and strategic planning and management evaluation,
          organization strategy development, organizational Management manual, Information Education
          Communication (IEC) materials development and dissemination, facilitation of development
          of organization directives documents and fundraising documents development.
          </p>
          <a className="btn btn-lg btn-primary rounded" href=''>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
      </div> 
</Grid>


{DisplayFull===true?
<Grid item xs={12}>
<ProjectEvaluationPresentation />
</Grid>
:null}


{DisplayFull===true?
<Grid item xs={12}>
<div className="wow fadeInUp" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp'}}>
<div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
      <h5 className="fw-bold text-primary text-uppercase">Our  Successfully Assignments</h5>
      <h1 className="mb-0">
         Successfully Assignments Conducted in Monitoring & Evaluation
      </h1>
</div>
<p className="m-0">
        Our consulting services are results-oriented. Emphasis is on the intensive involvement of the client
        in problem identification and clarification. Options in problem resolution are generated and form
        the basis of strategies, structures, and processes that respond to the needs of the client while keeping
        professionalism.
        The following are some of the assignments which have been completely performed by TINKAS M&E Consults;
        these assignments allow us to link our M&E capacity with our extensive program management expertise.
</p>

<Grid container spacing={1}>
<Grid item xs={12} sm={5}>
<img src='images/assignments.png' />
</Grid>
</Grid>



</div>
</Grid>
:null}


{DisplayFull===true?
<Grid item xs={12}>
<div className='table-content' id='project-table'>
<table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name of the assignment</th>
      <th scope="col">organization  and place </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Commissioned M&E assignment for routine M&E of SWASH/MHM project</td>
      <td>. Bukoba Rotary Club - Kagera , Tanzania </td>
    </tr>
    
    <tr>
      <th scope="row">2</th>
      <td>Institution capacity assessment and development of strategic plan </td>
      <td>Partage - Bukoba Municipal, Kagera Tanzania</td>
    </tr>

    <tr>
      <th scope="row">3</th>
      <td>Financial profitability and sustainability(co-optied)</td>
      <td>TANICA soft drink water factory Bukoba Municipal, Kagera Tanzania </td>
    </tr>

    <tr>
      <th scope="row">4</th>
      <td>Institutional capacity assessment</td>
      <td>ELCT/KAD Nyakahanga DDH Karagwe District, Kagera Tanzania</td>
    </tr>


    <tr>
      <th scope="row">5</th>
      <td>End Project evaluation</td>
      <td>Tumaini Children Centre (TCC) /NWD Kagera, Tanzania</td>
    </tr>

    <tr>
      <th scope="row">6</th>
      <td>Institution economic capacity assessment</td>
      <td>BLSS Bukoba municipal, Kagera Tanzania</td>
    </tr>


    <tr>
      <th scope="row">7</th>
      <td>Final evaluation of Strategic plan</td>
      <td>- Ndolage institute of health science(NIHS) Muleba, Kagera Tanzania</td>
    </tr>


    <tr>
      <th scope="row">8</th>
      <td>Final evaluation of Strategic plan</td>
      <td>Ndolage institute of health science(NIHS) Muleba, Kagera Tanzania</td>
    </tr>

    <tr>
      <th scope="row">9</th>
      <td>C4ED Lake zonal M&E plan document development</td>
      <td>Coalition for Early childhood development in Lake zone(C4ECD) Mwanza, Tanzania</td>
    </tr>



    <tr>
      <th scope="row">10</th>
      <td>Economic Capacity Assessment</td>
      <td>Josiah Kibira University College (JoKUCo), Bukoba Kagera Tanzania </td>
    </tr>


    <tr>
      <th scope="row">11</th>
      <td>Final evaluation of Strategic plan</td>
      <td>Rubya –DDH Muleba Kagera, Tanzania </td>
    </tr>


    <tr>
      <th scope="row">12</th>
      <td>Economic Capacity assessment</td>
      <td>- Kagera Medical Technical services  (KAMTES), Bukoba Kagera Tanzania</td>
    </tr>

    <tr>
      <th scope="row">13</th>
      <td>Diocesan Monitoring and Evaluation system development</td>
      <td>Evangelical Lutheran Church Tanzania NorthWestern Diocese Bukoba,Tanzania</td>
    </tr>


    <tr>
      <th scope="row">14</th>
      <td>Institutional self-Capacity Assessment (ICA)</td>
      <td>Josiah Kibira University College (JoKUCo), Bukoba Tanzania</td>
    </tr>


    <tr>
      <th scope="row">15</th>
      <td>Process evaluation of Combating Community Violence in Kagera region</td>
      <td>Human Rights Desks/NWD  Bukoba, Tanzania</td>
    </tr>



    <tr>
      <th scope="row">16</th>
      <td>Economic Capacity  Assessment  for Izimbya Lutheran</td>
      <td>DDH Bukoba DC Kager Tanzania</td>
    </tr>



    <tr>
      <th scope="row">17</th>
      <td>Research: Dietary intake on pregnant women in relation to haemoglobin concentration</td>
      <td>. Bukoba DC-Kagera, Tanzania</td>
    </tr>



    <tr>
      <th scope="row">18</th>
      <td>End evaluation of Capacity building project-TACDEN Kagera Chapter</td>
      <td>Kagera, Tanzania </td>
    </tr>

    <tr>
      <th scope="row">19</th>
      <td>Final evaluation of Strategic plan-Rubya school of Nursing</td>
      <td>Muleba Kagera,Tanzania</td>
    </tr>


    <tr>
      <th scope="row">20</th>
      <td>Economic Capacity assessment - ELCT/NWD Ndolage hospital</td>
      <td>Muleba Kagera,Tanzania</td>
    </tr>



    <tr>
      <th scope="row">21</th>
      <td>Strategic plans development/ projects evaluation/M&E Plan/Project proposals  for Mamas Hope organization for legal Assistance(MHOLA) </td>
      <td>Kagera,Tanzania</td>
    </tr>



    <tr>
      <th scope="row">22</th>
      <td>Routine Monitoring and Evaluation  for WASH project, Rotary club of Bukoba in</td>
      <td>Kibondo DC Kigoma</td>
    </tr>

    <tr>
      <th scope="row">23</th>
      <td>Strategic plan/M&E Plan/project proposals/Projects evaluations for </td>
      <td>Humuliza Organization, Muleba Kagera</td>
    </tr>


    <tr>
      <th scope="row">24</th>
      <td>M&E capacity building for ELCT Diocesan economist and planning managers</td>
      <td>LMC Arusha</td>
    </tr>


    <tr>
      <th scope="row">25</th>
      <td>M&E capacity Building for staff of  Agrithamani organization</td>
      <td>Kigoma,Kagera and Dodoma  Tanzania </td>
    </tr>

    <tr>
      <th scope="row">26</th>
      <td>Capacity building on online data colletion to head of health institution</td>
      <td>NWD Kagera Tanzania </td>
    </tr>


    <tr>
      <th scope="row">27</th>
      <td>Process evaluation of primary health care in Lake Tanganyika</td>
      <td>Morovian church Nkasi DC, Rukwa Region Tanzania</td>
    </tr>










  </tbody>
</table>  



</div>  
</Grid>
:null}







</Grid>
</div>
);


// Servicepage.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// Servicepage.defaultProps ={}


})
export  {Servicepage}

