import React ,{Fragment,useEffect}from 'react';
import './MonitoringEvaluationProcess.scss';
import {Grid,Button} from '@mui/material';









const  MonitoringEvaluationProcess = (({DisplayFull=true}) => { //--------------MAIN FUNCTION----------------
  
// const ApplicationState = useContext(Applicationcontext)

  //const {ResponsiveMediaQuery,UserProfile,Functions,App,AppplicationSetting} = ApplicationState

  //const {PersonalProfile,BusinessProfile} = UserProfile

  //const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

 // const {theme_light_color,theme_maincolor,theme_subcolor,business_name,company_logo,theme_decoration_color} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


 // const {mobile_xs,tablet_sm,desktop_md,desktop_xl} = ResponsiveMediaQuery







  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------





  

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------





  

}

// eslint-disable-next-line react-hooks/exhaustive-deps

},[])










// const MailNotificationTrigger=()=>{
   
//   //$('#mail-notification-dropdown').trigger('click')

//   document.getElementById('mail-notification-dropdown').click();

   

 
// }




//0767867862
//0782467852 kazembe 

//ln -s /etc/nginx/sites-available/tinkasmec.conf /etc/nginx/sites-enabled/

//sudo ln -s /etc/nginx/sites-available/tinkasmec.conf /etc/nginx/sites-enabled/tinkasmec.conf




return (   //--------RETURN HTML---------------------------
<Fragment>
<div id='MonitoringEvaluationProcess'>
<Grid container spacing={1}>


<Grid item xs={12}>
 <h1 className="mb-0">The Domain Of Monitoring & Evaluation Process</h1>

  <div className='EvaluationPhase'>
    <img src='images/EvaluationPhase.png' alt=''/>
  </div>




</Grid>



</Grid>
</div>  
</Fragment>
 
);


// MonitoringEvaluationProcess.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// MonitoringEvaluationProcess.defaultProps ={}


})
export  {MonitoringEvaluationProcess}

