import React ,{Fragment,useEffect,useContext}from 'react';
import './Homepage.scss';
import {Applicationcontext} from "../applicationcontext";
import {Grid,Container, Typography} from '@mui/material';
import {Aboutpage} from "../Aboutpage/Aboutpage";
import {ExperienceSection} from "../ExperienceSection/ExperienceSection";
import {Servicepage} from "../Servicepage/Servicepage";
import {MonitoringEvaluationProcess} from "../MonitoringEvaluationProcess/MonitoringEvaluationProcess";








const  Homepage = (() => { //--------------MAIN FUNCTION----------------
  
 const ApplicationState = useContext(Applicationcontext)


  const {ResponsiveMediaQuery,UserProfile,Functions,AppplicationSetting} = ApplicationState

  const {PersonalProfile,BusinessProfile} = UserProfile

  const {profile_photo,shopping_cart_items} = PersonalProfile

  const {top_navigation_link} = AppplicationSetting

  const {theme_maincolor,theme_subcolor,theme_light_color,theme_decoration_color,company_logo} = BusinessProfile


  //const {BusinessProfile} = UserProfile

 // const {profile_photo} = PersonalProfile

  //const {dark_theme} = AppplicationSetting

  //const {business_name} = BusinessProfile
 

 // const {AnimateToggleMobileMenu,onchageThemeMode} = Functions


  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery





  
  // var History = useHistory()






useEffect(()=>{ //------------COMPONENT DID MOUNT--------------------------------------




  

return()=>{//<<<<<<<<<<<----------------Component Wiil Unmount-------





  

}

//eslint-disable-next-line react-hooks/exhaustive-deps
},[])



// const LogoutCleaningCache = ()=>{
//   return new Promise(function(resolve,reject){

    
    
//     return resolve(Cookies.remove('account_csrftoken'))
// })
// }





// const LogoutFunction = ()=>{


//   LogoutCleaningCache().then(function(Response){


//    localStorage.setItem('logout_message','You_have_been_loged_out_successfully')
//     var LogoutPageReload = $('#logout-page-reload')
//     LogoutPageReload[0].click()

//   })

 
  
// }









return (   //--------RETURN HTML---------------------------
<Fragment>
<div id='Homepage'>
<Grid container spacing={2}>

     
<Grid item xs={12}>
  <Aboutpage  DisplayFull={false} />
</Grid>

   
  <Grid item xs={12}>
  <ExperienceSection />
  </Grid>


  <Grid item xs={12}>
  <Servicepage DisplayFull={false} />
  </Grid>





</Grid>
</div>

<br />

<MonitoringEvaluationProcess />
</Fragment>
 
);


// Homepage.propTypes = {

//   theme_maincolor:PropTypes.string.isRequired

// }
// Homepage.defaultProps ={}


})
export  {Homepage}

