import 'react-toastify/dist/ReactToastify.css'
import {useEffect}from 'react';
import * as React from 'react';
import {RouteNavigation} from "./RouteNavigation";





function App() {


useEffect(()=>{//----------COMPONENT DID MOUNT

            

return()=>{//<<<<<<<<<<<----------------Component Will Unmount
        
        
        
        
           
        
}
//eslint-disable-next-line react-hooks/exhaustive-deps
},[])

return (
<div className="App">

<RouteNavigation />



</div>
);
}
export default App//To enable url fetching