import React ,{useEffect,useState,Fragment} from 'react';
import "./Landingpage.scss"
import Cookies from 'js-cookie';
import $ from 'jquery';
import {Applicationcontext} from "./applicationcontext";
import {HttpServer_ApplicationMounting,HttponlineConnectionDetection} from "./httpservercall";
import {ThemeProvider,createTheme} from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Container,Divider,Typography} from '@mui/material';
import {LandingpageNavigation} from "./LandingpageNavigation";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import {HowToReg,Home,Login,Logout,DashboardCustomize,ShoppingCartCheckout,AccountCircle,Search,Close,LocationCity,Phone,WhatsApp,Email,Delete} from '@mui/icons-material';
import {Grid,Button} from '@mui/material';

import { toast ,ToastContainer} from 'react-toastify';
import {Link,useNavigate,useLocation} from "react-router-dom";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';



function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 60, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};






function Landingpage(props){ //-----------MAIN APP
  
const ResponsiveDimention = useTheme();

const mobile_xs = useMediaQuery(ResponsiveDimention.breakpoints.only('xs'));
const tablet_sm = useMediaQuery(ResponsiveDimention.breakpoints.only('sm'));
const desktop_md = useMediaQuery(ResponsiveDimention.breakpoints.only('md'));
const desktop_lg = useMediaQuery(ResponsiveDimention.breakpoints.only('lg'));
const desktop_xl = useMediaQuery(ResponsiveDimention.breakpoints.only('xl'));
  
const Location = useLocation()
const {pathname} = Location



const [ServerProcessLoading,setServerProcessLoading] = useState({
server_loading:null,
server_response_status:null,
server_message:null,
online_status:null,
application_mounted_status:null,
server_error_collection:(function(){
  var ServerErrorCollection = localStorage.getItem("server_error_collection")
  if(ServerErrorCollection !==null && ServerErrorCollection !==undefined){
       
    return JSON.parse(ServerErrorCollection)

  }else{
    
    localStorage.setItem("server_error_collection",JSON.stringify({}))
    return  {}
}   
})()
}
)
const [AppplicationSetting,setAppplicationSetting] = useState(
{  
top_navigation_link:[{name:'home',link:'/'},{name:'property',link:'/property'},{name:'services',link:'/services'},{name:'about us',link:'/about'},{name:'contact',link:'/contact'}],  
database_version:4,
database_name:'application_database',
application_state:'application_state',
indexedb_created:false,  
display_mobile_menu:false,
dark_theme:localStorage.getItem('dark_theme')==='true',
authenticated:(function(){
var token = Cookies.get("account_csrftoken")
if(token !==undefined && token !==null){
return true;
}else{
return false;
}   
})(),
authentication_token:(function(){
  var token = Cookies.get("USER_AUTH_TOKEN")
  if(token !==null && token !==undefined){
  return token;
  }else{
  return null;
  }   
})(),
server_ip:(function(){
    if(process.env.NODE_ENV==='production'){
     return "/";
    }else{ 
      return  "http://127.0.0.1:8000/";
     }

})(),
site_domain:(function(){
  if(process.env.NODE_ENV==='production'){

   return window.location.hostname
   
  }else{ 
    return  'homemarkets.store';
   }

})(),
service_worker:(function(){
    var service_worker = localStorage.getItem('service_worker')
    if(service_worker !==null && service_worker !==undefined){
      if(service_worker==='true'){

        return true;
      }else{
        return false;
      }
    }else{ return false;}   
  })()
  }
)


const [UserProfile,setUserProfile] = useState({
BusinessProfile:{
   company_logo:'logo.png',
   company_motto:'we work and grow together',
   company_name:'femsworldtz',
   theme_decoration_color: "#34282C",
   theme_maincolor: "#E30B5D",
   theme_subcolor: "#34282C",
   theme_light_color: "#fff",
   theme_text_dark_color: "#3A3B3C",
   PropertyTypeStructure:{
    residential:[
      {property_type_name:'house',parrent:'residential'},
      {property_type_name:'appartment',parrent:'residential'},
      {property_type_name:'villa',parrent:'residential'},
      {property_type_name:'cottage',parrent:'residential'},
      {property_type_name:'building',parrent:'residential'},
      {property_type_name:'plot',parrent:'residential'}
    ],
    commercial:[
      {property_type_name:'office',parrent:'commercial'},
      {property_type_name:'hotel',parrent:'commercial'},
      {property_type_name:'lodge',parrent:'commercial'},
      {property_type_name:'restaurant',parrent:'commercial'},
      {property_type_name:'plot',parrent:'commercial'},
    ],
    industrial:[
      {property_type_name:'warehouse',parrent:'industrial'},
      {property_type_name:'store',parrent:'industrial'},
      {property_type_name:'factory',parrent:'industrial'},
      {property_type_name:'industry',parrent:'industrial'},
      {property_type_name:'workshop',parrent:'industrial'},
      {property_type_name:'plot',parrent:'industrial'},
    ]
  }
},

 PersonalProfile:{
  first_name:null,
  last_name: null,
  middle_name:null,
  profile_photo:"profile_avatar.png",
  shopping_cart_items:(function(){
    var ShoppingCartItem = localStorage.getItem("shopping_cart_items")
    if(ShoppingCartItem !==null && ShoppingCartItem !==undefined){
    var shopping_cart_json = JSON.parse(ShoppingCartItem)  


  
     return shopping_cart_json
    }else{
    
      localStorage.setItem('shopping_cart_items',JSON.stringify({}))

      return {}
    }   
  })(),
  wishlist_items:(function(){
      var WishlistItems = localStorage.getItem("wishlist_items")
      if(WishlistItems  !==null && WishlistItems  !==undefined){
      var wishlist_json = JSON.parse(WishlistItems)  
  
  
    
       return wishlist_json
      }else{
      
        localStorage.setItem('wishlist_items',JSON.stringify({}))
  
        return {}
      }   
  })(),

 }
})








const [sidemenuToggle,setsidemenuToggle] = useState({
 
  display:false,
  section:null

})


const [GlobalmodalState,setGlobalmodalState] = useState({section:null})


const  AddShoppingCart_LocalStorage=(product) => {
  return new Promise(function(resolve,reject){
    
    var ShoppingCart = localStorage.getItem("shopping_cart_items")

    var {id} = product

    var CART_ITEM_STRUCTURE={
      user_id:null,  
      id:product.id,
      coverphoto:product.coverphoto,
      name:product.product_name, 
      fixed_price:product.price, 
      total_price:product.price,
      total_buyer_commission_reward:product.buyer_commission_reward,
      total_purchased_quantity:product.purchased_quantity
     }

     if(ShoppingCart !==null && ShoppingCart !==undefined){
 
     var SHOPPING_CART_STORAGE = JSON.parse(ShoppingCart)
 
     SHOPPING_CART_STORAGE[id] = CART_ITEM_STRUCTURE
 
     localStorage.setItem("shopping_cart_items",JSON.stringify(SHOPPING_CART_STORAGE)) 


     setUserProfile({...UserProfile,PersonalProfile:{...UserProfile.PersonalProfile,shopping_cart_items:SHOPPING_CART_STORAGE}})
  
     console.log('item_added')

     setTimeout(function(){



      toast.success(`${Object.keys(SHOPPING_CART_STORAGE).length} item added to cart`,{
        position: toast.POSITION.TOP_RIGHT,
        autoClose:200,
        autoClose:true,
        theme:'colored',
        toastId:'add-to-cart-notification'
      });

     
      return resolve({})

     },1000)
     
    
 
   }

 })
 }








const TextColorSetting = {
 dark_mode:'#b5b5b1',
 light_mode:'gray'
}


const theme = createTheme({
palette:{
  primary:{
    main: `${UserProfile.BusinessProfile.theme_maincolor}`,
  },
  secondary:{
    main: `${UserProfile.BusinessProfile.theme_maincolor}`,
  },
  info:{
    main:`${UserProfile.BusinessProfile.theme_decoration_color}`
  },
  mode: `${AppplicationSetting.dark_theme===true?'dark':'light'}`,
  // mode: 'dark',
  background:{
    //paper:'#090B23',
    //default:'#090B23'
  }
},//pallete configuration end here
typography:{
  body1:{

    // color:AppplicationSetting.dark_theme===true?TextColorSetting.dark_mode:TextColorSetting.light_mode ,

  },
  body2:{

    // color:AppplicationSetting.dark_theme===true?TextColorSetting.dark_mode:TextColorSetting.light_mode ,

  },
  caption:{
    color:AppplicationSetting.dark_theme===true?TextColorSetting.dark_mode:TextColorSetting.light_mode  ,
    
  },
  h3:{
    fontFamily:`font-family: 'Anton', sans-serif;`      
  }
},
shape:{
  borderRadius:0,
},
//spacing:8,
overrides:{
  MuiButton:{
    root:{
      textTransform:'none',
      
    }
  },

},
props:{
  MuiButton:{
    disableRipple:true,
    // variant:'text'
  },
  MuiButtonGroup:{
    disableRipple:true,
    // variant:'outlined'
  },
  MuiTextField:{
    InputLabelProps:{
      shrink:true
    }
  }
  
}

})   



const [server_online_status,setserver_online_status] = useState(null)


const Navigate = useNavigate()

window.addEventListener("load", () => {

window.addEventListener('online', () => {
  
  //online detection
  HttponlineConnectionDetection(AppplicationSetting.server_ip).then(function(JsonResponse){
    const {status} = JsonResponse
    if(status > 0){ //online

      setserver_online_status('online')
    }else{

      setserver_online_status('offline')
    }


  })
});

window.addEventListener('offline', () => {

    setserver_online_status('offline')
});
});




          





useEffect(()=>{///<<<<<<---------COMPONENT DID MOUNT-------------------------------------
console.log(`APPLICATION_MOUNTED:..`)






return ()=>{//<<<-------------- COMPONENT WILL UNMOUNT----








}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])



const ConfigureServerProcessResponse=(ACTIVE_SECTION_STORE_NAME=null,ServerResponse=null)=>{
  
  if(ACTIVE_SECTION_STORE_NAME !==null && ServerResponse !==null){

    const {server_message,server_response_status,method} = ServerResponse

    if(server_response_status >= 0){ //ACTIVATE ONLINE INDICATOR

        setserver_online_status('online')
      
    }
    setServerProcessLoading({...ServerProcessLoading,//DISABLE LOADING & UPDATE SERVER STATUS
      server_loading:false,
      server_message:server_message,
      server_response_status:server_response_status,
    })
      

    //NOTIFICATION SECTION
    if(server_response_status > 200){ //SERVER ERROR NOTIFICATION

      toast.info(`server error ${server_response_status}.`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:8000,
        autoClose:true,
        theme:'colored',
        toastId:'Landingpage-toast-notification-wrapper'
      });
      
    }

    if(server_response_status ==='offline'){ //SERVER ERROR NOTIFICATION

      toast.info(`your offline.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose:1000,
        autoClose:true,
        theme:'colored',
        toastId:'Landingpage-toast-bottom-notification'
      });
      
    }





  

    if(server_response_status===200 && method==='GET'){//200 INSTALL RECEIVED DATA FOR OFFLINE ACCESS
        var data;
        if(ServerResponse.hasOwnProperty('JsonData')){
          data = ServerResponse.JsonData
        }
        if(ServerResponse.hasOwnProperty('ArrayData')){
          data = ServerResponse.ArrayData
        }

        if(`${JSON.stringify(data)}`.length > 10){ //ensuring returned data are not empty

                                                  //DATABASE_NAME,DATABASE_VERSION,STORE_NAME,STORE_KEY,STORE_CONTENT
        //indexedbUpdateSingleDatabase(AppplicationSetting.database_name,AppplicationSetting.database_version,ACTIVE_SECTION_STORE_NAME,STORE_KEY,data)

      }

  

    }



 }
 
}
 


const RouteUrlMatchFunction =(BrowserRouteUrl,PatternMatch)=>{

  var BrowserRouteMatch = BrowserRouteUrl.split('/')

  if(BrowserRouteMatch !==undefined && BrowserRouteMatch!==null){
  
    if(BrowserRouteMatch[1]===PatternMatch.split('/')[1]){
  
  
      return true
    }else{return false}
  }else{
  
    return false
  }
  
  
}

const ActivateProcessLoading =(Loading)=>{
  if(Loading !==null && Loading !==undefined){

      if(Loading===true){

       setServerProcessLoading({...ServerProcessLoading,server_loading:true})

      }
      
      if(Loading===false){
       
      setServerProcessLoading({...ServerProcessLoading,server_loading:false})
     }
   


  }
     
}



const FigureToCurrencyFormator = (amount_figure) =>{
  if(typeof(amount_figure)==='number'){

  return amount_figure.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }else{
    return 0
  }
 
    
};



const StringFormatingFunction = (string,common_prefix=false)=>{
  try {
    var phase1 = string.replaceAll('&',' & ')
    
    if(common_prefix===true){
      var phase2 =  phase1.replaceAll('kids_','')
      var phase3 = phase2.replaceAll('women_','')
      var phase4 = phase3.replaceAll('men_','')

      var phase5 = phase4.replaceAll('_',' ')

      return phase5
     }


    var phase2 = phase1.replaceAll('_',' ')
  
    return phase2
     
   }catch(error){
    // console.log('error on string formatting'+error)
 
     return string;
   }
 }









 const sidemenuToggleFunction=(section)=>{
  if(section !==null && section !==undefined){

   setsidemenuToggle({section:section,display:!sidemenuToggle.display})
  }
 }




 const triggerGlobalModalFunction=(section)=>{
  if(section !==null && section !==undefined){


    setGlobalmodalState({section:section})

   
    
    $('#global-modal-trigger')[0].click()

  }
 }


 const RemoveCartItemFunction=(ID)=>{
  if(ID !==null && ID !==undefined){


   delete UserProfile.PersonalProfile.shopping_cart_items[ID]

   setUserProfile({...UserProfile})

   var ShoppingCart = localStorage.getItem("shopping_cart_items")

   if(ShoppingCart !==null && ShoppingCart !==undefined){

     
    localStorage.setItem('shopping_cart_items',JSON.stringify(UserProfile.PersonalProfile.shopping_cart_items))

   }


   

  }
 }

 



   



var SharedFunction ={
  ConfigureServerProcessResponse:ConfigureServerProcessResponse,
  ActivateProcessLoading:ActivateProcessLoading,
  FigureToCurrencyFormator:FigureToCurrencyFormator,
  sidemenuToggleFunction:sidemenuToggleFunction,
  triggerGlobalModalFunction:triggerGlobalModalFunction,
  ActivateProcessLoading:ActivateProcessLoading,
  AddShoppingCart_LocalStorage:AddShoppingCart_LocalStorage,
  StringFormatingFunction:StringFormatingFunction,


 }
 
 var ResponsiveMediaQuery ={
  mobile_xs:mobile_xs,
  tablet_sm:tablet_sm,
  desktop_md:desktop_md,
  desktop_lg:desktop_lg,
  desktop_xl:desktop_xl
  }



 
return( ///<<<<<<<<<-------------------Returning Main Landingpage---------------------
<div className='Landingpage' id={mobile_xs===true||tablet_sm===true?'MOBILE_TABLET_VIEW':'DESKTOP_VIEW'}>
<div className='Landingpage' id={mobile_xs===true?'MOBILE_VIEW_ONLY':tablet_sm===true?'TABLET_VIEW_ONLY':'DESKTOP_VIEW'}>
<><ToastContainer /></> 
<Applicationcontext.Provider value={{sidemenuToggle,UserProfile:UserProfile,AppplicationSetting:AppplicationSetting,server_online_status:server_online_status,ServerProcessLoading:ServerProcessLoading,App:AppplicationSetting,Functions:SharedFunction,theme:theme,ResponsiveMediaQuery:ResponsiveMediaQuery}}>
<div>
  {/* Topbar Start */}
  <div className="container-fluid bg-dark px-5 d-none d-lg-block">
    <div className="row gx-0">
      <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2" />Bukoba- Kagera, Tanzania</small>
          <small className="me-3 text-light"><i className="fa fa-phone-alt me-2" />+255 754 513 185  </small>
          <small className="text-light"><i className="fa fa-envelope-open me-2" />tinkanicolaus@gmail.com  </small>
        </div>
      </div>
      <div className="col-lg-4 text-center text-lg-end">
        <div className="d-inline-flex align-items-center" style={{height: 45}}>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-twitter fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-facebook-f fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-linkedin-in fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-instagram fw-normal" /></a>
          <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href><i className="fab fa-youtube fw-normal" /></a>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}


  {/* Navbar & Carousel Start */}
  <div className="container-fluid position-relative p-0">
    <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
      <a href="index.html" className="navbar-brand p-0">
        <h1 className="m-0"><i className="fa fa-user-tie me-2" />TinkasMec</h1>
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="fa fa-bars" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0">
          <a href="/" className="nav-item nav-link active">Home</a>
          <a href="services.html" className="nav-item nav-link">Services</a>
          <a href="/project.html" className="nav-item nav-link">Projects</a>
          <a href="/about.html" className="nav-item nav-link">About</a>
          {/* <a href="/contact" className="nav-item nav-link">Contact</a> */}
        </div>
        <butaton type="button" className="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search" /></butaton>
        <a href="/about.html" className="btn btn-primary py-2 px-4 ms-3">TinkasMec > WeBelieveInResults</a>
      </div>
    </nav>

    {/* HOMEPAGE SLIDER */}
    {pathname==='/'?
    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img className="w-100" src="homepage-slides/1.png" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 900}}>
              <h5 className="text-white text-uppercase mb-3 animated slideInDown">Welcome to Tinkas Company</h5>

              <h1 className="display-1 text-white mb-md-4 animated zoomIn">Executive M&E Presentation.</h1>
             
              {/* <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a>
              <a href className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contact Us</a> */}

            </div>
          </div>
        </div>
      
        <div className="carousel-item">
          <img className="w-100" src="homepage-slides/2.png" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 900}}>

              <h5 className="text-white text-uppercase mb-3 animated slideInDown">Tinkas Company Presentation  </h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                Facilitator demonstrating about SP theory of changes of ELCT
             </h1>
              
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <img className="w-100" src="homepage-slides/3.png" alt="Image" />
          <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
            <div className="p-3" style={{maxWidth: 900}}>

              <h5 className="text-white text-uppercase mb-3 animated slideInDown">Tinkas Company Limited ,We believe in result</h5>
              <h1 className="display-1 text-white mb-md-4 animated zoomIn">A Great Theme For Business Consultancy</h1>
              
            </div>
          </div>
        </div>


      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div> 
    :null}
  
  {pathname!=='/'?
  <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: 90}}>
    <div className="row py-5">
      <div className="col-12 pt-lg-5 mt-lg-5 text-center">
        <h1 className="display-4 text-white animated zoomIn">

          {pathname==='/services.html'?'our services':null}

          {pathname==='/project.html'?'our project':null}

          {pathname==='/about.html'?'about us':null}
        
        </h1>
        <a href className="h5 text-white">Home</a>

        <i className="far fa-circle text-white px-2" />
        
        <a href className="h5 text-white">

          {pathname==='/services.html'?'services':null}

          {pathname==='/project.html'?'project':null}

          {pathname==='/about.html'?'about us':null}

        </a>
      </div>
    </div>
  </div>
  :null}


  </div>
  {/* Navbar & Carousel End */}
  <div className="container py-5">
     
    <LandingpageNavigation  />


  </div>


  {/* Full Screen Search Start */}
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content" style={{background: 'rgba(9, 30, 62, .7)'}}>
        <div className="modal-header border-0">
          <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{maxWidth: 600}}>
            <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
            <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}







  {/* Footer Start */}
  <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s" id='footer-container'>
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-4 col-md-6 footer-about">
          <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
            <a href="index.html" className="navbar-brand">
              <h1 className="m-0 text-white"><i className="fa fa-user-tie me-2" />TinkasMec</h1>
              <img src='logo.png' alt='' className='footer-logo'/>  
            </a>
            <p className="mt-3 mb-4">
              
             The firm is a multidisciplinary M&E consultancy services majorly in health care, education, 
             theology/religions, legal and human rights, Nutrition and food technology, economics. Our commitment 
             is to provide “brand name” advisory services of the highest quality, with the agility and flexibility 
             that contemporary realities demand.
            </p>
            <form action>
              <div className="input-group">
                <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                <button className="btn btn-dark">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-8 col-md-6">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-12 pt-5 mb-5">
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="text-light mb-0">Head office Bukoba </h3>
              </div>

              <div className="d-flex mb-2">
                <i className="bi bi-geo-alt text-primary me-2" />
                <p className="mb-0">Jamuhuri- Uganda road , P.O.BOX 385 , Bukoba- Kagera, Tanzania</p>
              </div>

              <div className="d-flex mb-2">
                <i className="bi bi-telephone text-primary me-2" />
                <p className="mb-0">+255 754 513 185  </p>
              </div>


              <div className="d-flex mb-2">
                <i className="bi bi-envelope-open text-primary me-2" />
                <p className="mb-0">tinkanicolaus@gmail.com</p>
              </div>
              <div className="d-flex mb-2">
                <i className="bi bi-envelope-open text-primary me-2" />
                <p className="mb-0">tinkanico@yahoo.co.uk</p>
              </div>
              <div className="d-flex mb-2">
                <i className="bi bi-envelope-open text-primary me-2" />
                <p className="mb-0">tinkasconsults@gmail.com </p>
              </div>


              <div className="d-flex mt-4">
                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal" /></a>
                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal" /></a>
                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal" /></a>
                <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram fw-normal" /></a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="text-light mb-0">Quick Links</h3>
              </div>
              <div className="link-animated d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                <a className="text-light mb-2" href="/about.html"><i className="bi bi-arrow-right text-primary me-2" />About Us</a>
                <a className="text-light mb-2" href="/services.html"><i className="bi bi-arrow-right text-primary me-2" />Our Services</a>
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Latest Blog</a>
                <a className="text-light" href="/"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                <h3 className="text-light mb-0">Popular Links</h3>
              </div>
              <div className="link-animated d-flex flex-column justify-content-start">
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                <a className="text-light mb-2" href="/about.html"><i className="bi bi-arrow-right text-primary me-2" />About Us</a>
                <a className="text-light mb-2" href="/services.html"><i className="bi bi-arrow-right text-primary me-2" />Our Services</a>
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Meet The Team</a>
                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2" />Latest Blog</a>
                <a className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid text-white" style={{background: '#061429'}}>
    <div className="container text-center">
      <div className="row justify-content-end">
        <div className="col-lg-8 col-md-6">
          <div className="d-flex align-items-center justify-content-center" style={{height: 80}}>
            <p className="mb-0">© <a className="text-white border-bottom" href="/">TinkasMec</a>. All Rights Reserved <br /> developer contact:  williamjonasan@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
</div>



 </Applicationcontext.Provider>   
</div>
</div>
)
}
export default Landingpage //To enable url fetching

