const ISJson =(str)=>{
try {
    JSON.parse(str);
} catch (e){

    return false;
}
return true;
}


const JsonCleaner = (json)=>{
  var cleaned =  JSON.parse(json.replace(/'/g,'"'))
    
    return cleaned
  }



const HttpApplicationMounting = (server_ip,authentication_token)=>{
  return new Promise(function(resolve,reject){
     var status = undefined 
      var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    
      } 
      return fetch(`${server_ip}api/adminapp/application_mounting/${authentication_token}`,
      {
      method:'GET', 
      credentials:'include',
      headers: Headers,
      }).then(function(Response){ 
    
       status = Response.status
      
      return Response.text()
      }).then(function(TextResponse){
       var ISJSON = ISJson(TextResponse)

       if(ISJSON){//CHECK IF ITS JSON
         var JsonResponse = JSON.parse(TextResponse)

         JsonResponse['server_response_status'] = status

            if(status===200){ //------SUCCESS RESPONSE

             const {JsonData} = JsonResponse

  
               return resolve(JsonResponse)

             }else{


                      
               return resolve(JsonResponse)
             }
           
        }else{
         // ServerResponse = {"authentication_token":None,"server_message":None,"error_collection":[]}

            console.log("NOT-A-JSON") 
            return resolve({data:{},authentication_token:null,server_response_status:status,server_message:'SERVER_ERROR'})
        }
        }).catch(function(error){//--------------CATCH ALL THE ERROR
         const ServerCallError = `${error}`.includes('Failed to fetch')===true?'offline':undefined

         // return indexdbFetchSingleObject('application_state','application_state').then(function(JsonResponse){
         //   //({data:{},status:200})
         //     const {data,status} = JsonResponse

   
         //     return resolve({JsonData:data,server_response_status:ServerCallError,server_message:status})

         // })

         return resolve({JsonData:{},server_response_status:ServerCallError,server_message:ServerCallError})

  
         
     })  
    
})
}



const HttponlineConnectionDetection = (server_ip)=>{
 return new Promise(function(resolve,reject){
     var status = undefined 
      var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    
      } 
      return fetch(`${server_ip}api/adminapp/online_connection_detection`,
      {
      method:'GET', 
      credentials:'include',
      headers: Headers,
     //  body:JSON.stringify(LoginForm),
      }).then(function(Response){ 
    
       status = Response.status
      
      return Response.text()
      }).then(function(TextResponse){
       var ISJSON = ISJson(TextResponse)
       if(ISJSON){//CHECK IF ITS JSON
         var JsonResponse = JSON.parse(TextResponse)
         JsonResponse['status'] = status


        

        return resolve(JsonResponse)    
        }
        }).catch(function(error){//--------------CATCH ALL THE ERROR
         
          const status = `${error}`.includes('Failed to fetch')===true?'offline':undefined

  
         return resolve({status:status,server_message:'offline'})  //--USER ERROR
     })  
    
})
}








const HttpFetchGlobalTrendingsales = (server_ip,pagination_start,pagination_interval)=>{
  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    
    fetch(`${server_ip}api/landingpage_app/fetch_all_product/${pagination_start}/${pagination_interval}`,
    {method:'GET', 
    headers: Headers
    }).then(function(Response){ 
  
    status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
      
    var ISJSON = ISJson(TextResponse)
      
    if(ISJSON){//CHECK IF ITS JSON
      
      var JsonResponse = JSON.parse(TextResponse)
      JsonResponse['server_response_status'] = status
      JsonResponse['method'] = 'GET'
      
      if(status===200){ //------SUCCESS RESPONSE


  


       return resolve(JsonResponse)
      }else{ //---INTERNAL SERVER ERROR



      return resolve({ArrayData:{},server_response_status:status,method:'GET'})
     }




   }else{

    return resolve({ArrayData:{},server_response_status:status,method:'GET'})

  }}).catch(function(error){ //--------------CATCH ALL THE ERROR
    
      const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
        

      console.log('ERROR',error)
    
      return resolve({ArrayData:{},server_response_status:server_status,method:'GET'})
  



    })  
  
  })
}





const HttpFetchByCategory=(server_ip,CategoryParameter)=>{
  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    
    fetch(`${server_ip}api/landingpage_app/fetch_by_category`,
    {method:'POST', 
    headers: Headers,
    body:JSON.stringify(CategoryParameter)
    }).then(function(Response){ 
  
    status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
      
    var ISJSON = ISJson(TextResponse)
      
    if(ISJSON){//CHECK IF ITS JSON
      
      var JsonResponse = JSON.parse(TextResponse)
      JsonResponse['server_response_status'] = status
      JsonResponse['method'] = 'GET'
      
      if(status===200){ //------SUCCESS RESPONSE


  


       return resolve(JsonResponse)
      }else{ //---INTERNAL SERVER ERROR



      return resolve({ArrayData:{},server_response_status:status,method:'GET'})
     }




   }else{

    return resolve({ArrayData:{},server_response_status:status,method:'GET'})

  }}).catch(function(error){ //--------------CATCH ALL THE ERROR
    
      const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
        

      console.log('ERROR',error)
    
      return resolve({ArrayData:{},server_response_status:server_status,method:'GET'})
  



    })  
  
  })
}



const HttpCategoryFilterFunction=(server_ip,CategoryParameter)=>{
  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    
    fetch(`${server_ip}api/landingpage_app/category_filter`,
    {method:'POST', 
    headers: Headers,
    body:JSON.stringify(CategoryParameter)
    }).then(function(Response){ 
  
    status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
      
    var ISJSON = ISJson(TextResponse)
      
    if(ISJSON){//CHECK IF ITS JSON
      
      var JsonResponse = JSON.parse(TextResponse)
      JsonResponse['server_response_status'] = status
      JsonResponse['method'] = 'GET'
      
      if(status===200){ //------SUCCESS RESPONSE


  


       return resolve(JsonResponse)
      }else{ //---INTERNAL SERVER ERROR



      return resolve({ArrayData:{},server_response_status:status,method:'GET'})
     }




   }else{

    return resolve({ArrayData:{},server_response_status:status,method:'GET'})

  }}).catch(function(error){ //--------------CATCH ALL THE ERROR
    
      const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
        

      console.log('ERROR',error)
    
      return resolve({ArrayData:{},server_response_status:server_status,method:'GET'})
  



    })  
  
  })
}








const HttpFetchSingleItem=(server_ip,ItemID)=>{
  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    
    fetch(`${server_ip}api/landingpage_app/fetch_single_product/${ItemID}`,
    {method:'GET', 
    headers: Headers
    }).then(function(Response){ 
  
    status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
     
      var ISJSON = ISJson(TextResponse)
      if(ISJSON===true){
        var JsonResponse = JSON.parse(TextResponse)
        JsonResponse['server_response_status'] = status
        JsonResponse['method'] = 'GET'
     

        if(status===200){




          return resolve(JsonResponse)
        }else{





          return resolve(JsonResponse)
        }
      
      }else{

        console.log('NOT_JSON_FILE')
        resolve({JsonData:{},server_response_status:status,method:'GET'})
      }
    }).catch(function(error){ //--------------CATCH ALL THE ERROR

      const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
          
        

    
      return resolve({JsonData:{},server_response_status:server_status,method:'GET'})
  
   })  
  
  })
}




const HttpSearchItem=(server_ip,search_query,pagination_start=0,pagination_interval=10)=>{
  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    
    fetch(`${server_ip}api/landingpage_app/search_product/${search_query}/${pagination_start}/${pagination_interval}`,
    {method:'GET', 
    headers: Headers
    }).then(function(Response){ 
  
    status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
     
      var ISJSON = ISJson(TextResponse)
      if(ISJSON===true){
        var JsonResponse = JSON.parse(TextResponse)
        JsonResponse['server_response_status'] = status
        JsonResponse['method'] = 'GET'
     

        if(status===200){




          return resolve(JsonResponse)
        }else{





          return resolve(JsonResponse)
        }
      
      }else{

        console.log('NOT_JSON_FILE')
        resolve({ArrayData:[],server_response_status:status,method:'GET'})
      }
    }).catch(function(error){ //--------------CATCH ALL THE ERROR

      const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
          
        

    
      return resolve({ArrayData:[],server_response_status:server_status,method:'GET'})
  
   })  
  
  })
}



const HttpUserRegistration=(server_ip,site_domain,authentication_token,RegistrationForm)=>{

  //SERVER CREDENTIAL INJECTION
  RegistrationForm['site_domain'] = site_domain
  RegistrationForm['authentication_token'] = authentication_token

  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    
    fetch(`${server_ip}api/landingpage_app/user_registration`,
     {
      method:'POST', 
      headers: Headers,
       body:JSON.stringify(RegistrationForm)
     }).then(function(Response){ 
      
       status = Response.status
    
      return Response.text()
    }).then(function(TextResponse){
     
      var ISJSON = ISJson(TextResponse)
      if(ISJSON===true){
        var JsonResponse = JSON.parse(TextResponse)
        JsonResponse['server_response_status'] = status
        JsonResponse['method'] = 'POST'
        
        if(status===200){




          return resolve(JsonResponse)
        }else{


          return resolve(JsonResponse)
        }
      
      }else{

        console.log('NOT_JSON_FILE')
        resolve({JsonData:{},server_response_status:status,method:'GET'})
      }
    }).catch(function(error){ //--------------CATCH ALL THE ERROR

      const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
          
        

    
      return resolve({JsonData:{},server_response_status:server_status,method:'GET'})
  
   })  
  
  })
}




  

export{
HttpApplicationMounting,
HttponlineConnectionDetection,
HttpFetchGlobalTrendingsales,
HttpFetchSingleItem,
HttpFetchByCategory,
HttpCategoryFilterFunction,
HttpSearchItem,
HttpUserRegistration
}
